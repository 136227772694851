:root{
  --col1:#BBBF3B;
  --col2:#93A603;
  --nero:#2F2F2F;
  --grigio:rgba(47,47,47,0.2);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 200vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  border: 0px soslid red;
  font-family: 'Ubuntu';
  box-sizing: border-box;
  color: var(--nero);
}
.scrittaB{
  font-size: 48px;
  font-weight: bold;
  margin: 0px;
}
.scrittaN{
  font-size: 32px;
  font-weight: normal;
  margin: 0px;
}
button{
  width: 145px;
  height: 65px;
  color: white;
  background-color: var(--nero);
  border-radius: 25px;
  border: none;
  font-size: 24px;
  font-weight: normal;
}

button:hover{
  cursor: pointer;
}
