.loginPageWrap{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.loginPage{
    height: auto;
    width: 950px;
    background-color: var(--col1);
    border-radius: 50px;
    margin: auto;
    padding: 50px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    gap: 20px;
}
.scrittaB {
    padding-bottom: 50px;
}
input{
    width: 400px;
    height: 65px;
    border-radius: 25px;
    border: none;
    font-size: 24px;
    padding: 25px;
}
.inputUn{
    margin-top: auto;
}
.inputBt{
    margin-bottom: auto;
}

.registerPageWrap{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.registerPage{
    height: auto;
    width: 950px;
    background-color: var(--col1);
    border-radius: 50px;
    margin: auto;
    padding: 50px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    gap: 20px;
}