.card{
    width: 640px;
    height: 320px;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    padding: 30px;
    background-color: var(--col1);
}
.imgCard{
    width: 256px ;
    height: 256px;
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.cardDX{
    width: 300px;
    margin-left: 20px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.titoloCard{
    font-size: 32px;
    margin: 0px;
}
.descrizioneCard{
    font-size: 20px;
    font-weight: normal;
    margin: 0px;
    margin-top: 7px;
}
.boxInfo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 15px;
    margin-top: 10px;
}
.infoItem{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.itemIcons{
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}
.imgCardIMG{
    object-fit: cover;

}



.bottiniCard{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding-top: 20px;
    gap: 10px;
}
.bottone1Card{
    height: 100%;
    width: 70%;
    font-size: 24px;
    color: white;
    font-weight: bold;
}
.bottone2Card{
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}
.favIco{
    color: white;
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}
.bottone1Card,.bottone2Card{
    border-radius: 20px;
    margin: none;
    border: none;
    background-color: var(--nero);
}