.HomePage{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.scrittaBigBig,.scrittaBig{
    position: relative;
    z-index: 99999999;
    text-align: left;
    align-self: flex-start;
    margin-left: 280px;
}
.scrittaBigBig{
    font-size: 5vw;
    top: 25px;
    margin-bottom: 0px;
}
.scrittaBig{
    font-size: 2.8vw;
    font-weight: normal;
    top:10px;
}

.sfondo{
    position: absolute;
    top: 0px;
    width: 100%;
    height: fit-content;
}
#onde,#compSf{
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    /* top: -200px; */
    top: -21vh;
    z-index: 0;
}
.compSfIMG{
    width: 100%;
}
.searchBar{
    width: 1100px;
    height: 65px;
    gap: 12px;
    position: relative;
    z-index: 99999999;
    display: flex;
    flex-direction: row;
    top: 50px;
}
.searchBar div,.searchBar button{
    height: 100%;
    border-radius: 25px;
    background-color: white;
}
.searchBar button{
    background-color: var(--nero);
}
.bar1,.bar2,.bar3,.bar4{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 24px;
    color:var(--grigio);
}
.bar1{
    width: 400px;
}

.bar2{
    width: 300px;
}
.bar3{
    width: 175px;
}
.bar4{
    width: 145px;
    border: none;
    background-color: var(--nero);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}