.p2{
    position: relative;
    z-index: 99999999999;
    width: 100%;
    top: 190px;
    padding: 65px;
    padding-top: 0px;
    background: url("./Subtract.png");
    height: 200vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0px 352px;
}
.cardWrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 25px;
    position: relative;
}
.cardWrap::-webkit-scrollbar {
    display: none;
}
.cardWrapWrap{
    height: fit-content;
    overflow: hidden;
    position: relative;
    margin-top: 100px;

}

.copriEl{
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 9999999999999999999999999999999;
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    right: -20px;
}
.copriEl1,.copriEl2{
    width: 100%;
    height: 100%;
}
.copriEl2{
  background-image: linear-gradient(to right, rgba(255,255,255,0), var(--col2) 95%);
}
.copriEl1{
    background-image: linear-gradient(to right, rgba(255,255,255,0), white 95%);
}