.NavBar{
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: relative;

}
.navPrinc{
    width: 100%;
    height: 17vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 60px;
    padding-right: 60px;
    background-color: var(--col1);

}
.navSec{
    width: 100%;
    height: 70px;
    background-color: var(--col2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 60px;
    padding-right: 60px;
}
.navSX,.navDX,.navCN{
    display: flex;
    height: fit-content;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.navSX{
    font-weight: 200;
    font-size: 20px;
    gap: 20px;
}
.navDX{
    gap: 20px;
}
.scrittaLogo{
    font-weight: bold;
    font-size: 36px;
}
.scrittaNav{
    font-weight: normal;
    font-size: 24px;
}
.scrittaNav:last-child{
    font-weight: bold;
}
.scrittaNav:nth-child(2){
    margin-left: 60px;
}
html .navDX .scrittaNav{
    font-weight: normal;
}
.material-symbols-outlined {
  font-size: 32px;
  font-variation-settings:
  'FILL' 1,
  'wght' 600,
  'GRAD' 0,
  'opsz' 24
}
.material-symbols-outlined.fav,.material-symbols-outlined.user{
    font-variation-settings:
    'FILL' 1,
    'wght' 600,
    'GRAD' 0,
    'opsz' 24
}



.scritteNavSx,.scritteNavDx{
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.scrittaNavS{
    font-size: 22px;
    font-weight: 500;
}